import React, { useState, useEffect, useContext } from "react";
import FilterTable from "../../common/FilterTable";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import AppButton from "../../common/AppButton";
import { Link } from "react-router-dom";
import { CostingsApi } from "@unity/components";
import ApiLoader from "../../common/ApiLoader";
import Loading from "../../common/Loading";

export default function WeeklyLabour({ setValue, handleChange, filter, bg, admin }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);

  const getData = async () => {
    setLoading({ status: true, data: 'Updating, please wait...' });
    const res = await CostingsApi.getLabourReport(filter);
    setData(res.data);
    setLoading({ status: false });
  };


  const activeColumns = [
    { name: "abc_name", label: "ABC Name" },
    { name: "employee_name", label: "Employee Name" },
    { name: "hours_logged", label: "Hours logged" },
    {
      name: "date_logged",
      label: "Date logged",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let output = data[dataIndex].date_logged ? data[dataIndex].date_logged : "Not Set!";
           
          return (
            <Link style={{ color: data[dataIndex].date_logged ? "black" : "red" }} to={'/abc/view/' + data[dataIndex].abc_id}>{output}</Link>
          );
        },
      },
    },
    { name: "logged_by_name", label: "Hours logged by" }
  ];

  const deletableOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: "none",
    onRowsDelete: (rowsDeleted) => {},
  };
  
  
  useEffect(() => {
    getData();
  }, []);


  if (admin && data) {
    return (
      <>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-section padding-lg">
                            <div className="form-row">
                                <div className="form-group col-lg-3">
                                    <AppButton
                                      text="back"
                                      style={{
                                        background: "light_gray",
                                        color: "black",
                                        marginLeft: 15
                                      }}
                                      onClick={() => setValue(0)}
                                    />
                                </div>
                                <div className="form-group col-lg-3">
                                    <InputLabel>From</InputLabel>
                                    <TextField
                                        type="date"
                                        name="from"
                                        value={filter.from}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="form-group col-lg-3">
                                    <InputLabel>To</InputLabel>
                                    <TextField
                                        type="date"
                                        name="to"
                                        value={filter.to}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="form-group col-lg-3">
                                    <AppButton
                                      text="submit"
                                      style={{
                                        background: bg,
                                        color: "white",
                                        marginLeft: 15
                                      }}
                                      onClick={() => getData()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FilterTable columns={activeColumns} data={data} options={deletableOptions} />

        <ApiLoader
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
