import React, { useContext } from 'react';
import AuthContext from '../../../services/AuthContext';

export default function Widget (props) {
    const context = useContext(AuthContext);

    return(
        <>
            <button
              className="report-counter"
              style={{ background: props.bg }}
              disabled={!props.button}
              onClick={() => props.setValue(props.tag)}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <p className="report-title" style={{ color: 'white', textAlign: 'center' }}>{props.title}</p>
                  </div>
                </div>
                {props.count && <p style={{ color: 'white', textAlign: 'center' }} className="count">{props.count}</p>}
              </div>
            </button>
        </>
    )
}
