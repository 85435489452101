import { MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CostingsApi, PersonnelApi, CodesApi } from "@unity/components";
import AppButton from '../../common/AppButton';
import JobCostingSettingsOptions from './JobCostingSettingsOptions';
import { codeAlias } from "../../../constants";

export default function JobCostingSettings({ context }) {
    const [data, setData] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const [personnel, setPersonnel] = useState([]);

    const getPersonnelData = async () => {
        const res = await PersonnelApi.getPersonnel();
        setPersonnel(res.data)
    }

    const getData = async () => {
        const codes = await CodesApi.getCodes();
        const cc = codes.data.find(a => a.const_alias === codeAlias["ChargeCodes"]);
        const pm = codes.data.find(b => b.const_alias === codeAlias["PaymentMethods"]);
        const pc = codes.data.find(c => c.const_alias === codeAlias["PeriodicCodes"]);
        const vat = codes.data.find(d => d.const_alias === codeAlias["VatRates"]);
        const notifications = await CostingsApi.getNotifications();

        setData({
            pc_id: pc.id,
            pc: [...pc.options],
            pm_id: pm.id,
            pm: [...pm.options],
            cc_id: cc.id,
            cc: [...cc.options],
            vat_id: vat.id,
            vat: [...vat.options],
            notifications: [...notifications.data]
        });
    }

    const handleAdd = (type) => {
        const newData = { ...data };
        const oid = type + '_id';
        newData[type].push({ type_id: data[oid] });
        setData(newData);
    }

    const handleItemChange = (e, type, key) => {
        const newData = { ...data };
        newData[type][key]["name"] = e.target.value;
        newData[type][key]["update"] = true;
        setData(newData);
    }

    const handleItemDelete = async (id) => {
        await CodesApi.deleteOption(id);
        await getData();
    }

    const handleItemRestore = async (id) => {
        await CodesApi.restoreOption(id);
        await getData();
    }

    const handleItemsUpdate = async (type) => {
        const len = data[type].length;

        for (let i = 0; i < len; i++) {
            if (data[type][i].update && data[type][i].id) {
                await CodesApi.updateOption(data[type][i].id, data[type][i]);
            } else if (data[type][i].update) {
                await CodesApi.createOption(data[type][i]);
            } else {
                // Nothing as this will not have a name added so would fail validation!
            }
        }

        setEditActive(false);
    }

    const handleItemSaveCancel = (type, key) => {
        const newData = { ...data };
        newData[type].splice(key, 1)
        setData(newData);
    }

    const handleAddNotification = () => {
        const newData = { ...data };
        newData['notifications'].push({})
        setData(newData)
    }

    const handleNotificationAddCancel = (key) => {
        const newData = { ...data };
        newData['notifications'].splice(key, 1)
        setData(newData)
    }

    const handleSaveNotificationSingle = async (key) => {
        await CostingsApi.saveNotifications(data['notifications'])
        setEditActive(false)
    }

    const handleNewUser = (contact_id, key) => {
        const newData = { ...data };
        const userObj = personnel.find(e => e.contact_id === contact_id);
        newData['notifications'][key]['contact_id'] = contact_id;
        newData['notifications'][key]['name'] = userObj.name;
        newData['notifications'][key]['role'] = userObj.role;
        setData(newData)
    }

    const handleNotificationUserDelete = (key) => {
        const newData = { ...data };
        newData['notifications'].splice(key, 1);
        setData(newData)
    }

    
    useEffect(() => {
        getData();
        getPersonnelData()
    }, [])


    if (data) {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="block" style={{ padding: 15 }}>
                            <h6 style={{ margin: 0 }}>Receive Notifications</h6>
                            <sub>Who within this system will receive notifications when job costings
                                are created, or when job costings are marked for review. </sub>

                            <div style={{ display: 'flex', width: '100%', marginTop: 20, flexWrap: 'wrap', flexDirection: 'row', flexGrow: 'inherit' }}>
                                {data.notifications.map((item, key) => {
                                    return (


                                        <div style={{ flex: 4, maxWidth: 350 }}>
                                            <div style={{ display: 'flex', width: 350, marginTop: 20, marginBottom: 20, alignItems: 'center', flexDirection: 'row' }}>
                                                {item.contact_id ?
                                                    <>
                                                        <div style={{ flex: 1 }}><img
                                                            className="profile"
                                                            src={
                                                                context.profile
                                                                    ? context.profile
                                                                    : "https://www.gravatar.com/avatar/94d093eda664addd6e450d7e9881bcad?s=32&d=identicon&r=PG"
                                                            }
                                                            alt="Profile"
                                                        />
                                                        </div>
                                                        <div style={{ flex: 2 }}>
                                                            <div>{item.name}</div>
                                                            <div>{item.role}</div>
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                            {editActive === "notifications" &&
                                                                <AppButton
                                                                    onClick={() => handleNotificationUserDelete(key)}
                                                                    btnIcon="delete"
                                                                    style={{ marginTop: 20, marginRight: 15, background: 'red', color: 'white' }}
                                                                />}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div style={{ flex: 1, paddingRight: 25 }}>
                                                            <Select
                                                                fullWidth
                                                                value={item.contact_id}
                                                                onChange={(e) => handleNewUser(e.target.value, key)}>
                                                                {personnel.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.contact_id}>{item.name}</MenuItem>;
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <div style={{ flex: 4 }}>
                                                            <AppButton
                                                                onClick={() => handleNotificationAddCancel(key)}
                                                                btnIcon="close"
                                                                style={{ marginTop: 20, marginRight: 15, background: 'red', color: 'white' }}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                            {editActive === "notifications" ?
                                <>
                                    <AppButton
                                        onClick={() => setEditActive(false)}
                                        text="cancel"
                                        btnIcon="close"
                                        style={{ marginTop: 20, marginRight: 15, background: 'red', color: 'white' }}
                                    />
                                    <AppButton
                                        onClick={() => handleAddNotification()}
                                        text="add"
                                        btnIcon="add"
                                        style={{ marginTop: 20, marginRight: 15, background: context.theme.sidebar.background, color: 'white' }}
                                    />
                                    <AppButton
                                        onClick={() => handleSaveNotificationSingle()}
                                        text="save changes"
                                        btnIcon="save"
                                        style={{ marginTop: 20, marginRight: 15, background: 'green', color: 'white' }}
                                    />
                                </>
                                :
                                <AppButton
                                    onClick={() => setEditActive("notifications")}
                                    text="edit"
                                    btnIcon="edit"
                                    style={{ marginTop: 20, background: context.theme.sidebar.background, color: 'white' }}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <JobCostingSettingsOptions
                            handleItemsUpdate={handleItemsUpdate}
                            handleItemSaveCancel={handleItemSaveCancel}
                            handleItemDelete={handleItemDelete}
                            handleItemRestore={handleItemRestore}
                            handleItemChange={handleItemChange}
                            handleAdd={handleAdd}
                            type="cc"
                            fields="text"
                            editActive={editActive}
                            setEditActive={setEditActive}
                            data={data.cc}
                            title={"Charge Codes"}
                        />
                    </div>

                    <div className="col-lg-3">
                        <JobCostingSettingsOptions
                            handleItemsUpdate={handleItemsUpdate}
                            handleItemSaveCancel={handleItemSaveCancel}
                            handleItemDelete={handleItemDelete}
                            handleItemRestore={handleItemRestore}
                            handleItemChange={handleItemChange}
                            handleAdd={handleAdd}
                            type="pm"
                            fields="text"
                            editActive={editActive}
                            setEditActive={setEditActive}
                            data={data.pm}
                            title={"Payment Methods"}
                        />
                    </div>

                    <div className="col-lg-3">
                        <JobCostingSettingsOptions
                            handleItemsUpdate={handleItemsUpdate}
                            handleItemSaveCancel={handleItemSaveCancel}
                            handleItemDelete={handleItemDelete}
                            handleItemRestore={handleItemRestore}
                            handleItemChange={handleItemChange}
                            handleAdd={handleAdd}
                            type="pc"
                            fields="text"
                            editActive={editActive}
                            setEditActive={setEditActive}
                            data={data.pc}
                            title={"Periodic Codes"}
                        />
                    </div>

                    <div className="col-lg-3">
                        <JobCostingSettingsOptions
                            handleItemsUpdate={handleItemsUpdate}
                            handleItemSaveCancel={handleItemSaveCancel}
                            handleItemDelete={handleItemDelete}
                            handleItemRestore={handleItemRestore}
                            handleItemChange={handleItemChange}
                            handleAdd={handleAdd}
                            type="vat"
                            fields="number"
                            editActive={editActive}
                            setEditActive={setEditActive}
                            data={data.vat}
                            title={"VAT Rates"}
                        />
                    </div>
                </div>
            </div >
        )
    } else {
        return <p>Loading</p>
    }
}