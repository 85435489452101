import React, { useState, useEffect, useContext } from "react";
import FilterTable from "../../common/FilterTable";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import AppButton from "../../common/AppButton";
import { CostingsApi } from "@unity/components";
import { Link } from "react-router-dom";
import ApiLoader from "../../common/ApiLoader";
import Loading from "../../common/Loading";

export default function Profitability({ setValue, handleChange, filter, bg, admin }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);

  const getData = async () => {
    const res = await CostingsApi.getProfitReport(filter);
    setData(res.data);
  };


  const activeColumns = [
    { name: "abc_name", label: "ABC Name" },
    { name: "customer", label: "Customer" },
    { name: "est_revenue", label: "Est Revenue" },
    { name: "est_materials", label: "Est Materials" },
    {
      name: "est_labour",
      label: "Est Labour",
      options: {
        customBodyRenderLite: (dataIndex) => {
           
          return (
            <Link style={{ color: data[dataIndex].est_labour_warn ? "red" : "black" }} to={'/abc/view/' + data[dataIndex].abc_id}>{data[dataIndex].est_labour}</Link>
          );
        },
      },
    },
    { name: "est_profit", label: "Est Profit" },
    {
      name: "logged_labour",
      label: "Sum of Labour Logged",
      options: {
        customBodyRenderLite: (dataIndex) => {
           
          return (
            <Link style={{ color: data[dataIndex].logged_labour_warn ? "red" : "black" }} to={'/abc/view/' + data[dataIndex].abc_id}>{data[dataIndex].logged_labour}</Link>
          );
        },
      },
    },
    { name: "variations_logged", label: "Sum of Variations Logged" },
    { name: "actual_profit", label: "Current Actual Profit/Loss" }
  ];

  const deletableOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: "none",
    onRowsDelete: (rowsDeleted) => {},
  };
  
  
  useEffect(() => {
    getData();
  }, []);


  if (admin && data) {
    return (
      <>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-section padding-lg">
                            <div className="form-row">
                                <div className="form-group col-lg-3">
                                    <AppButton
                                      text="back"
                                      style={{
                                        background: "light_gray",
                                        color: "black",
                                        marginLeft: 15
                                      }}
                                      onClick={() => setValue(0)}
                                    />
                                </div>
                                <div className="form-group col-lg-3">
                                    <InputLabel>From</InputLabel>
                                    <TextField
                                        type="date"
                                        name="from"
                                        value={filter.from}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="form-group col-lg-3">
                                    <InputLabel>To</InputLabel>
                                    <TextField
                                        type="date"
                                        name="to"
                                        value={filter.to}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="form-group col-lg-3">
                                    <AppButton
                                      text="submit"
                                      style={{
                                        background: bg,
                                        color: "white",
                                        marginLeft: 15
                                      }}
                                      onClick={() => getData()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FilterTable columns={activeColumns} data={data} options={deletableOptions} />

        <ApiLoader
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
