import React, { useEffect, useState } from "react";
import JobCostingForm from "../../components/modules/abc/JobCostingForm";
import Loading from "../../components/common/Loading";
import { Link } from "react-router-dom";
import { CostingsApi, ClientsApi, CodesApi, PersonnelApi, AppHeaderComponent, AppButtonComponent, BusinessApi } from "@unity/components";
import { authorisedGroups } from "../../constants";
import { useHistory } from "react-router";

export default function JobCostingView({ route, context }) {

  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [live, setLive] = useState({ worked: [], variations: [] });
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState(false)
  const [liveErrors, setLiveErrors] = useState(false);
  const [clients, setClients] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  const [productCategories, setProductCategories] = useState(false);
  const [users, setUsers] = useState(false);
  const [creditCheck, setCreditCheck] = useState(false);

  const authorisedExtendedForm = authorisedGroups[context.auth.group_uuid];
  const totals = [
    { category: "revenue", value: null, warning: false },
    { category: "labour", value: null, warning: false },
    { category: "materials", value: null, warning: false },
    { category: "worked", value: null, warning: false },
    { category: "variations", value: null, warning: false },
    { category: "estimated_total", value: null, warning: false },
    { category: "actual_total", value: null, warning: false }
];

  let history = useHistory();

  const getData = async () => {
    const res = await CostingsApi.getCostingsSingle(route.match.params.id);
    setData(res.data);
    await getAllClients(res.data.overview.customer_name);
    if (res.data.overview.associated_bu) {
      const res2 = await CodesApi.subOptionsIndexForOption(res.data.overview.associated_bu);
      setProductCategories(res2.data);
    }
  }

  const getLive = async () => {
    const res = await CostingsApi.getLiveLines(route.match.params.id);
    setLive(res.data);
  }

  const getAllClients = async (search = "") => {
    try {
      const rules = [{field: "is_client", operator: "=", value: true}];
      if(search !== "") rules.push({field: "customer_name", operator: "LIKE", value: `%${search}%`});

      const res = await BusinessApi.businessIndexFiltered({
        rules: rules,
        page: 1
      });
      if(!res.ok) throw "Call Failed";

      setClients(res.data.data);
    } catch (error) {
      alert(`Failed to Pull Clients ${error}`);
    }
  }

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  }

  const getCategories = async (id) => {
    const res = await CodesApi.subOptionsIndexForOption(id);
    setProductCategories(res.data);
  }

  const getUserData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setUsers(res.data);
  }

  const handleChange = (e, root, key) => {}
  const handleLiveChange = (e, root, key) => {}
  const handleAddRecord = (root) => {}
  const handleAddLiveRecord = (root) => {}
  const handleRemoveRecord = (root, key) => {}
  const handleRemoveLiveLabour = (root, key) => {}

  // Page total for variations section
  const totalVariations = () => {
    var i = 0;
    live["variations"].map((item, key) => {
      var qty = parseFloat(item.duration ? item.duration : 0);
      var net = parseFloat(item.net ? item.net : 0);
      var vat = parseFloat(item.vat ? item.vat / 100 : 0);
      var t = (qty * net) + ((qty * net) * vat);
      i += t;
    });
    return i.toFixed(2);
  }

  // Page total for labour section
  const totalLabour = () => {
    var i = 0;
    data["labour"].map((item, key) => {
      var t = parseFloat(item.rate ? item.rate : 0) * parseFloat(item.qty ? item.qty : 1);
      i += t;
    })
    return i.toFixed(2);
  }

  // Page total for live labour section
  const totalLiveLabour = () => {
    var i = 0;
    live["worked"].map((item, key) => {
      var t = parseFloat(item.rate ? item.rate : 0) * parseFloat(item.qty ? item.qty : 1);
      i += t;
    });
    return i.toFixed(2);
  }

  // Page total for revenue section
  const totalRevenue = () => {
    var i = 0;
    data["revenue"].map((item, key) => {
      var qty = parseFloat(item.duration ? item.duration : 0);
      var net = parseFloat(item.net ? item.net : 0);
      var vat = parseFloat(item.vat ? item.vat / 100 : 0);
      var t = (qty * net) + ((qty * net) * vat);
      i += t;
    });
    return i.toFixed(2);
  }

  // Page total for materials section
  const totalMaterial = () => {
    var i = 0;
    data["materials"].map((item, key) => {
      var qty = parseFloat(item.quantity ? item.quantity : 0);
      var net = parseFloat(item.net_cost ? item.net_cost : 0);
      var vat = parseFloat(item.vat_cost ? item.vat_cost / 100 : 0);
      var dur = parseFloat( item.duration !== undefined ? item.duration : 1 );
      var t = ((qty * net) + ((qty * net) * vat)) * dur;
      i += t;
    });
    return i.toFixed(2);
  }

  // Row gross figure
  const totalVariationSingle = (key) => {
    var item = live["variations"][key];
    var net = parseFloat(item.net ? item.net : 0);
    var vat = parseFloat(item.vat ? item.vat / 100 : 0);
    var t = net + (net * vat);
    return t.toFixed(2);
  }

  const handleBusinessChange = async (id) => {
    const newData = { ...data };
    newData["overview"]["associated_bu"] = id;
    setData(newData);

    await getCategories(id);
  }

  const handleCompleteState = async (id, state) => {
    setLoading({ status: true, data: 'Updating ABC completed status , please wait...' });
    const res = await CostingsApi.updateCompleted(id, { completed: state });
    
    if (res.errors) {
      setLoading({ status: true, data: 'Unable to update completed status on ABC!' });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      setTimeout(() => {
        history.push('/abc/index')
      }, 3000);
    }
  }
  

  useEffect(() => {
    getData();
    getLive();
    // getAllClients();
    getBusinesses();
    getUserData();
  }, []);

  if(loading?.state) return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1vh"
    }}>
      <CircularProgress/>
      <Typography>Loading Form Contents...</Typography>
    </div>
  )


  if (data && users) {
    return (
      <>
        <AppHeaderComponent context={context} theme={context.theme}
          addNew={() => <Link to={"/abc/create"}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }
              }
            />
          </Link>}
          editItem={() => !data.overview.requested_approval && !data.overview.approved ? 
            <Link to={`/abc/edit/${route.match.params.id}`}>
              <AppButtonComponent
                text="Edit"
                btnIcon="edit"
                stylename="primary"
                style={
                  {
                    background: context.theme.top_menu.button_background,
                    color: context.theme.top_menu.button_font,
                  }
                }
              />
            </Link> : null}
          name="abc" subpage="view" subpage2={data.overview.name} />
        <div style={{ paddingTop: 120 }}>
          <JobCostingForm
            data={data} setData={setData}
            live={live}
            totals={totals}
            step={step} setStep={setStep}
            creditCheck={creditCheck} setCreditCheck={setCreditCheck}
            context={context}
            handleAddRecord={handleAddRecord}
            handleAddLiveRecord={handleAddLiveRecord}
            handleRemoveRecord={handleRemoveRecord}
            handleRemoveLiveLabour={handleRemoveLiveLabour}
            handleChange={handleChange}
            handleLiveChange={handleLiveChange}
            totalVariationSingle={totalVariationSingle}
            totalVariations={totalVariations}
            totalRevenue={totalRevenue}
            totalLabour={totalLabour}
            totalLiveLabour={totalLiveLabour}
            totalMaterial={totalMaterial}
            users={users}
            errors={errors}
            liveErrors={liveErrors}
            clients={clients}
            businesses={businesses}
            productCategories={productCategories}
            handleBusinessChange={handleBusinessChange}
            type="view"
            authorisedExtendedForm={authorisedExtendedForm}
            handleCompleteState={handleCompleteState}
            getAllClients={getAllClients}
          />
        </div>
      </>
    )
  } else {
    return <Loading />
  }


}
