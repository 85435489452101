import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import JobCosting from "./pages/job-costing/index";
import JobCostingView from "./pages/job-costing/view";
import JobCostingEdit from "./pages/job-costing/edit";
import JobCostingCreate from "./pages/job-costing/create";
import Dashboard from "./pages/dashboard/index";


export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider value={{ ...props }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access["abc"]["A"] && props.auth.access["abc"]["R"] && (
                      <Route
                        path="/dashboard/index"
                        exact
                        component={(route) => (
                          <Dashboard
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                    {props.auth.access["abc"]["A"] && props.auth.access["abc"]["R"] && (
                      <Route
                        path="/abc/index"
                        exact
                        component={(route) => (
                          <JobCosting
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                    {props.auth.access["abc"]["A"] && props.auth.access["abc"]["R"] && (
                      <Route
                        path="/abc/view/:id"
                        exact
                        component={(route) => (
                          <JobCostingView
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                    {props.auth.access["abc"]["A"] && props.auth.access["abc"]["U"] && (
                      <Route
                        path="/abc/edit/:id"
                        exact
                        component={(route) => (
                          <JobCostingEdit 
                            route={route}
                            context={context}
                            history={history}
                          />
                        )}
                      />
                    )}
                    
                    {props.auth.access["abc"]["A"] && props.auth.access["abc"]["C"] && (
                      <Route
                        path="/abc/create"
                        exact
                        component={(route) => (
                          <JobCostingCreate
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
