import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FilterTable from "../../common/FilterTable";
import AppButton from "../../common/AppButton";
import { CostingsApi } from "@unity/components";
import ApiLoader from "../../common/ApiLoader";
import Loading from "../../common/Loading";

export default function CompleteIndex({ context, create, read, edit, admin, index_params }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);

  const getJobs = async () => {
    const res = await CostingsApi.getCompleteCostings(index_params);
    setData(res.data);
  };

  const handleDuplicate = async (id) => {
    setLoading({ status: true, data: 'Duplicating ABC, please wait...' });
    const res = await CostingsApi.duplicateCostings(id, {});

    if (res.errors) {
      setLoading({ status: true, data: 'Unable to duplicate ABC!' });
      setTimeout(() => {}, 3000);
    } else {
      setLoading({ status: false });
    }
    await getJobs()
  }

  const activeColumns = [
    { name: "ref", label: "Reference" },
    { name: "author_name", label: "Author" },
    { name: "name", label: "Name" },
    { name: "customer_name", label: "Customer Name" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Link
                to={{
                  pathname: `/abc/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              {create && data[dataIndex].approved &&
                <AppButton
                  text="duplicate"
                  btnIcon="file_copy"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 15
                  }}
                  onClick={() => handleDuplicate(data[dataIndex].id)}
                />
              }

            </div>
          );
        },
        filter: false,
      },
    },
  ];

  const deletableOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: "none",
    onRowsDelete: (rowsDeleted) => {},
  };
  
  
  useEffect(() => {
    getJobs();
  }, []);


  if (read && data) {
    return (
      <>
        <FilterTable columns={activeColumns} data={data} options={deletableOptions} />
        <ApiLoader status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <Loading />;
  }
}
