import React, { useContext, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FileBase64 from "react-file-base64";
import { CanModuleAdmin } from "../../../services/Permissions";
import Menu from "../../../components/modules/abc/Menu";
import AppButton from "../../../components/common/AppButton";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { CostingsApi, CodesApi } from "@unity/components";
import { CircularProgress, Tooltip, Typography } from "@mui/material";


const INPUTTIMEER = 1500;
export default function JobCostingForm({
    setLoading, data, setData, live, totals, step, setStep, creditCheck, setCreditCheck, checkBox, context,
    handleChange, handleLiveChange, handleAddRecord, handleAddLiveRecord, handleRemoveRecord, handleRemoveLiveRecord,
    totalVariationSingle, users, handleClientChange, handleBusinessChange, authorisedExtendedForm, productCategories,
    totalVariations, totalLabour, totalLiveLabour, type, errors, liveErrors, clients, businesses, totalRevenue, totalMaterial,
    handleCheckbox, handleCheckChange, handleCompleteState, handleVariationApproval, getAllClients, loading, variationErrors
}) {
    const approver = CanModuleAdmin("abc");
    const [cc, setCC] = useState([]);
    const [pc, setPC] = useState([]);
    const [pm, setPM] = useState([]);
    const [vat, setVAT] = useState([]);

    const [inputTimeout, setInputTimeout] = useState(false);

    const [variantsLoading, setVariantsLoading] = useState([]);

    async function getData() {
        const res = await CostingsApi.chargeCodeIndex();
        const res2 = await CostingsApi.paymentMethodIndex();
        const res3 = await CostingsApi.periodicCodeIndex();
        const res4 = await CodesApi.getVatRates();
        setCC(res.data);
        setPC(res3.data);
        setPM(res2.data);
        setVAT(res4.data);
    }

    const getCreditCheck = async () => {
        if (data.finance.credit_check_id) {
            const res = await CostingsApi.getCreditCheck(data.finance.credit_check_id);
            if (!res.errors) {
                setCreditCheck(res.data);
            }
        } else {
            const res = await CostingsApi.clientCreditCheck(data.overview.customer_id);
            if (!res.errors) {
                const newData = { ...data };
                newData["finance"]['credit_check_id'] = res.data.id;
                setData(newData);
                const newData2 = { ...creditCheck };
                newData2[''];
                setCreditCheck(res.data);
            }
        }
    };

    const getPdf = async (id) => {
        const res = await CostingsApi.downloadDocument(id);
        if(res.data) {
            var win = window.open(res.data, "_blank");
            win.focus();
        }
    };

    const uploadPdf = async (e) => {
        setLoading({ status: true, data: 'Uploading document, please wait...' });

        const newData = { ...creditCheck };
        newData["file_path"] = e;
        setCreditCheck(newData);

        const res = await CostingsApi.saveCreditCheck(newData);

        if (res.errors) {
            setLoading({ status: true, data: 'Unable to upload credit check...' });
            setTimeout(() => {
                setLoading({ status: false });
            }, 3000);
        } else {
            const newData = { ...data };
            newData["finance"]['credit_check_id'] = res.data.id;
            setData(newData);
            setCreditCheck(res.data);
            setLoading({ status: false });
        }
    };

    // Row gross figure
    const calcRevGross = (key) => {
        var i = 0;
        var net = parseFloat(data.revenue[key].net ? data.revenue[key].net : 0);
        var vat = parseFloat(data.revenue[key].vat ? data.revenue[key].vat : 0);
        i = net + (vat / 100 * net);
        return i.toFixed(2);
    };

    // Row gross figure
    const calcMatCost = (key) => {
        var i = 0;
        var qty = parseFloat(data.materials[key].quantity ? data.materials[key].quantity : 0);
        var net = parseFloat(data.materials[key].net_cost ? data.materials[key].net_cost : 0);
        i = qty * net;
        return i.toFixed(2);
    };

    // Overview page estimated profit
    const revenue = () => {
        var warn = false;
        var warnRev = false;

        var rev = 0;
        data['revenue'].map((item, key) => {
            rev += (parseFloat(item.net ? item.net : 0) * parseFloat(item.duration ? item.duration : 0));
            if (!item.duration) {
                warnRev = true;
            }
        });

        var lab = 0;
        data['labour'].map((item2, key) => {
            lab += parseFloat(item2.cost ? item2.cost : 0);
            if (!item2.cost) {
                warn = true;
            }
        });

        var mat = 0;
        data['materials'].map((item3, key) => {
            mat += ((parseFloat(item3.net_cost ? item3.net_cost : 0) * parseFloat(item3.quantity ? item3.quantity : 0)) * parseFloat(item3.duration !== undefined ? item3.duration : 1) );
        });

        var t = rev - lab - mat;

        totals[0].value = rev;
        totals[0].warning = warnRev;
        totals[1].value = lab;
        totals[1].warning = warn;
        totals[2].value = mat;
        totals[5].value = t;

        return t.toFixed(2);
    };

    // Overvire page contribution profit
    const actualProfit = () => {
        var warn = false;
        var warnVar = false;

        var rev = 0;
        data['revenue'].map((item, key) => {
            rev += (parseFloat(item.net ? item.net : 0) * parseFloat(item.duration ? item.duration : 0));
        });

        var lab = 0;
        live['worked'].map((item2, key) => {
            lab += parseFloat(item2.gross ? item2.gross : 0);
            if (!item2.gross) {
                warn = true;
            }
        });

        var mat = 0;
        data['materials'].map((item3, key) => {
            mat += ((parseFloat(item3.net_cost ? item3.net_cost : 0) * parseFloat(item3.quantity ? item3.quantity : 0)) * parseFloat(item3.duration !== undefined ? item3.duration : 1) );
        });

        var variation = 0;
        live['variations'].map((item4, key) => {
            variation += (parseFloat(item4.net ? item4.net : 0) * parseFloat(item4.duration ? item4.duration : 0));
            if (!item4.duration) {
                warnVar = true;
            }
        });

        var t = (rev - lab - mat) + variation;

        totals[3].value = lab;
        totals[3].warning = warn;
        totals[4].value = variation;
        totals[4].warning = warnVar;
        totals[6].value = t;

        return t.toFixed(2);
    };

    const searchClient = () => {
        if (clients) {
            return clients.find((c) => {
                if (c._id === data.overview.customer_id)
                    return c;
            });
        } else {
            return "";
        }
    };

    const handleVariantChange = async (item) => {
        let v = [...variantsLoading];
        v.push(item.id);

        setVariantsLoading(v);
        await handleVariationApproval(item.id);
        item.approved = !item.approved;
        setVariantsLoading([...variantsLoading]);
    }

    var revenueVar = revenue();
    var actualProfitVar = actualProfit();


    useEffect(() => {
        getData();
        getCreditCheck();
    }, []);

    if(loading?.status) return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <CircularProgress/>
            <Typography>
                Loading Form Data...
            </Typography>
        </div>
    )

    
    if (step === 1) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Overview</h4>

                                <div className="form-row">
                                    <div className="form-group col-lg-2">
                                    <InputLabel></InputLabel>
                                        <TextField
                                            label="Reference"
                                            value={data.overview.ref}
                                            name="ref"
                                            fullWidth={true}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="form-group col-lg-4">
                                    <InputLabel></InputLabel>
                                        <TextField
                                            label="Project Name"
                                            value={data.overview.name}
                                            error={errors && errors.overview.name ? true : false}
                                            helperText={errors && errors.overview.name}
                                            name="name"
                                            fullWidth={true}
                                            onChange={(e) => handleChange(e, "overview", false)}
                                            InputProps={{
                                                readOnly: (type === "view" || data.overview.approved) ? true : false,
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        { type !== "view" && !data.overview.approved ?
                                            <>
                                            <InputLabel></InputLabel>
                                            <Autocomplete
                                                fullWidth
                                                defaultValue={searchClient()}
                                                onChange={(e, val) => handleClientChange(val)}
                                                onInputChange={(e, input) => {
                                                    if(inputTimeout) clearTimeout(inputTimeout);
                                                    setInputTimeout(
                                                        setTimeout(async () => {
                                                            await getAllClients(input);
                                                            setInputTimeout(false);
                                                        }, INPUTTIMEER)
                                                    );
                                                }}
                                                loading={Boolean(inputTimeout)}
                                                options={clients}
                                                getOptionLabel={(option) => option.customer_name}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Customer Name"/>
                                                )}
                                            />
                                            </> 
                                            :
                                            <>
                                            <InputLabel>Customer Name</InputLabel>
                                            <TextField
                                                fullWidth
                                                value={data.overview.customer_name}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-lg-12">
                                        <TextField
                                            label="Project Description"
                                            name="description"
                                            error={errors && errors.overview.description ? true : false}
                                            helperText={errors && errors.overview.description}
                                            fullWidth={true}
                                            value={data.overview.description}
                                            onChange={(e) => handleChange(e, "overview", false)}
                                            InputProps={{
                                                readOnly: (type === "view" || data.overview.approved) ? true : false,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-lg-4">
                                        <InputLabel style={{ marginBottom: 3, fontSize: "13px" }}>Associated BU</InputLabel>
                                        <Select
                                            fullWidth
                                            name="associated_bu"
                                            value={data.overview.associated_bu ? data.overview.associated_bu : ""}
                                            error={errors && errors.overview.associated_bu ? true : false}
                                            helperText={errors && errors.overview.associated_bu}
                                            onChange={(e) => handleBusinessChange(e.target.value)}
                                            inputProps={{
                                                readOnly: (type === "view" || data.overview.approved) ? true : false,
                                            }}>
                                            {businesses && businesses.map((item, key) => {
                                                return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                            })}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <InputLabel style={{ marginBottom: 3, fontSize: "13px" }}>Associated Product Category</InputLabel>
                                        {data.overview.associated_bu ?
                                            <Select
                                                name="product_category"
                                                fullWidth
                                                value={data.overview.product_category}
                                                error={errors && errors.overview.product_category ? true : false}
                                                helperText={errors && errors.overview.product_category}
                                                onChange={(e) => handleChange(e, "overview", false)}
                                                inputProps={{
                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                }}>
                                                {productCategories && productCategories.map((item, key) => {
                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                            :
                                            <Select
                                                value={0}
                                                name="product_category"
                                                disabled
                                                fullWidth>
                                                <MenuItem value={0}>Please choose a business unit</MenuItem>
                                            </Select>
                                        }
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <InputLabel style={{ marginBottom: 3, fontSize: "13px" }}>Requested By</InputLabel>
                                        <Select
                                            name="requested_by_id"
                                            fullWidth
                                            value={data.overview.requested_by_id}
                                            error={errors && errors.overview.requested_by_id ? true : false}
                                            helperText={errors && errors.overview.requested_by_id}
                                            onChange={(e) => handleChange(e, "overview", false)}
                                            inputProps={{
                                                readOnly: (type === "view" || data.overview.approved) ? true : false,
                                            }}>
                                            {users && users.map((item, key) => {
                                                return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                            })}
                                        </Select>

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-4">
                                        <TextField
                                            label="Estimated Profit"
                                            fullWidth={true}
                                            name="estimated_profit"
                                            value={revenueVar}
                                            error={revenueVar < 0 && "Revenue is a loss"}
                                            helperText={"Using Estimated Labour (excl. Variations)"}
                                            onChange={(e) => handleChange(e, "overview", false)}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <TextField
                                            label="Contribution Profit"
                                            fullWidth={true}
                                            name="actual_profit"
                                            value={actualProfitVar}
                                            helperText="Using Live Labour and Variations"
                                            onChange={(e) => handleChange(e, "overview", false)}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <TextField
                                            label="Last Updated"
                                            fullWidth={true}
                                            value={data.overview.last_updated}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-lg-4">
                                        <InputLabel style={{ marginBottom: 3, fontSize: "13px" }}>ABC Status</InputLabel>
                                        <Select
                                            name="completed"
                                            fullWidth
                                            value={data.overview.completed}
                                            onChange={(e) => handleCompleteState(data.overview.id, e.target.value)}
                                            inputProps={{
                                                readOnly: ((type === "view" && !data.overview.completed) || !data.overview.approved) ? true : false,
                                            }}>
                                            <MenuItem key={0} value={false}>Active</MenuItem>
                                            <MenuItem key={1} value={true}>Completed</MenuItem>
                                        </Select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (step === 2) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Revenue</h4>
                                {type != "view" && !data.overview.approved && <AppButton onClick={() => handleAddRecord("revenue")} text="add new" btnIcon="add" style={{ background: context.theme.sidebar.background, color: 'white', marginTop: 10 }} />}
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Net</TableCell>
                                                <TableCell align="left">VAT (%)</TableCell>
                                                <TableCell align="left">Gross</TableCell>
                                                <TableCell align="left">CC</TableCell>
                                                <TableCell align="left">PM</TableCell>
                                                <TableCell align="left">PC</TableCell>
                                                <TableCell align="left">Months Required</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.revenue.map((item, key) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                value={item.name}
                                                                fullWidth={true}
                                                                name="name"
                                                                type="text"
                                                                onChange={(e) => handleChange(e, "revenue", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.net}
                                                                fullWidth={true}
                                                                type="number"
                                                                name="net"
                                                                onChange={(e) => handleChange(e, "revenue", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                fullWidth 
                                                                value={item.vat}
                                                                name="vat"
                                                                onChange={(e) => handleChange(e, "revenue", key)}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}>
                                                                {vat.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.name}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={calcRevGross(key)}
                                                                fullWidth={true}
                                                                name="gross"
                                                                type="number"
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select name="cc"
                                                                value={item.cc}
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "revenue", key)}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}>
                                                                {cc.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select name="pm"
                                                                value={item.pm}
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "revenue", key)}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}>
                                                                {pm.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select name="pc"
                                                                value={item.pc}
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "revenue", key)}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}>
                                                                {pc.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                name="duration"
                                                                value={item.duration}
                                                                onChange={(e) => handleChange(e, "revenue", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {type !== "view" && !data.overview.approved && <AppButton btnIcon="remove" style={{ background: 'red', color: 'white' }} onClick={() => handleRemoveRecord("revenue", key)} />}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Revenue Gross : <b>{totalRevenue()}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (step === 3) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Finance Approval</h4>

                                {data.overview.requested_approval && approver ? (
                                    <>
                                    <div className="form-row">
                                        <div className="form-group col-lg-4">
                                            <TextField
                                                label="Credit Check Score"
                                                value={creditCheck.score ? creditCheck.score : ""}
                                                name="score"
                                                fullWidth={true}
                                                type="text"
                                                error={!creditCheck.score ? true : false}
                                                helperText={!creditCheck.score ? "Credit score is required for document upload!" : ""}
                                                onChange={(e) => handleCheckChange(e)}
                                                InputProps={{
                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                }}
                                            />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <AppButton
                                              text="view credit check"
                                              btnIcon="visibility"
                                              style={{
                                                background: creditCheck.pdf_id ? 'green' : 'secondary',
                                                color: "white",
                                                marginLeft: 15
                                              }}
                                              onClick={() => creditCheck.pdf_id ? getPdf(creditCheck.pdf_id) : false}
                                            />
                                        </div>
                                        {type !== "view" && !data.overview.approved && creditCheck.score &&
                                            <div className="form-group col-lg-4">
                                                <h4>Add New</h4>
                                                <FileBase64
                                                  multiple={false}
                                                  onDone={(e) => uploadPdf(e)}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-lg-4">
                                            <TextField
                                                label="Checked By"
                                                value={data.finance.checked_name ? data.finance.checked_name : ""}
                                                fullWidth={true}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <TextField
                                                label="Checked Date"
                                                value={data.finance.checked_date ? data.finance.checked_date : ""}
                                                fullWidth={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    {type !== "view" && !data.overview.approved &&
                                        <div className="form-row">
                                            <div className="form-group col-lg-4">
                                                  <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkBox}
                                                            onChange={(e) => handleCheckbox()}
                                                        />
                                                    }
                                                    label="Checked"
                                                  />
                                            </div>
                                        </div>
                                    }
                                    </>
                                ) : data.finance.checked ? (
                                    <div className="form-row">
                                        <div className="form-group col-lg-4">
                                            <TextField
                                                label="Checked By"
                                                value={data.finance.checked_name}
                                                fullWidth={true}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <TextField
                                                label="Checked Date"
                                                value={data.finance.checked_date}
                                                fullWidth={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p style={{ color: 'red' }}>Approval Not Requested!</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (step === 4) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Labour</h4>
                                {type !== "view" && !data.overview.approved && <AppButton onClick={() => handleAddRecord("labour")} text="add new" btnIcon="add" style={{ background: context.theme.sidebar.background, color: 'white', marginTop: 10 }} />}

                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                <TableCell align="left">Who</TableCell>
                                                <TableCell align="left">What</TableCell>
                                                <TableCell align="left">Time Spent</TableCell>
                                                <TableCell align="left">Hourly Rate</TableCell>
                                                <TableCell align="left">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.labour.map((item, key) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                value={item.type}
                                                                type="text"
                                                                name="type"
                                                                fullWidth={true}
                                                                onChange={(e) => handleChange(e, "labour", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.name}
                                                                type="text"
                                                                name="name"
                                                                fullWidth={true}
                                                                onChange={(e) => handleChange(e, "labour", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.what}
                                                                name="what"
                                                                type="text"
                                                                fullWidth={true}
                                                                onChange={(e) => handleChange(e, "labour", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.qty}
                                                                type="number"
                                                                name="qty"
                                                                fullWidth={true}
                                                                onChange={(e) => handleChange(e, "labour", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.rate}
                                                                type="number"
                                                                name="rate"
                                                                fullWidth={true}
                                                                onChange={(e) => handleChange(e, "labour", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.cost}
                                                                type="number"
                                                                name="cost"
                                                                fullWidth={true}
                                                                onChange={(e) => handleChange(e, "labour", key)}
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {type !== "view" && !data.overview.approved && <AppButton btnIcon="remove" style={{ background: 'red', color: 'white' }} onClick={() => handleRemoveRecord("labour", key)} />}
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Labour Rate : <b>{totalLabour()}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>

                            <div className="page-section padding-lg">
                                <h3 style={{ paddingTop: 10, paddingBottom: 10 }}>Materials</h3>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Suppliers</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Unit Cost</TableCell>
                                                <TableCell>Net Value</TableCell>
                                                <TableCell>VAT (%)</TableCell>
                                                <TableCell>PC</TableCell>
                                                <TableCell>Duration</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.materials.map((item, key) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                value={item.type}
                                                                fullWidth={true}
                                                                type="text"
                                                                name="type"
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                value={item.name}
                                                                fullWidth={true}
                                                                type="text"
                                                                name="name"
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                value={item.quantity}
                                                                fullWidth={true}
                                                                type="number"
                                                                name="quantity"
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                value={item.net_cost}
                                                                fullWidth={true}
                                                                type="number"
                                                                name="net_cost"
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                value={calcMatCost(key)}
                                                                fullWidth={true}
                                                                type="number"
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select
                                                                name="vat_cost"
                                                                value={item.vat_cost}
                                                                fullWidth
                                                                disabled={true}>
                                                                {vat.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.name}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select
                                                                name="pc"
                                                                value={item.pc}
                                                                fullWidth
                                                                disabled={true}>
                                                                {pc.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                value={item.duration}
                                                                type="number"
                                                                fullwidth={true}
                                                                disabled={true}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Material Gross : <b>{totalMaterial()}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (step === 5) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Live Labour</h4>
                                {type !== "view" && <AppButton onClick={() => handleAddLiveRecord("worked")} text="add new" btnIcon="add" style={{ background: context.theme.sidebar.background, color: 'white', marginTop: 10 }} />}

                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                <TableCell align="left">Who</TableCell>
                                                <TableCell align="left">When</TableCell>
                                                <TableCell align="left">What</TableCell>
                                                <TableCell align="left">Time Spent</TableCell>
                                                <TableCell align="left">Hourly Rate</TableCell>
                                                <TableCell align="left">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {live.worked.map((item, key) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                value={item.type}
                                                                type="text"
                                                                name="type"
                                                                fullWidth={true}
                                                                //error={props.errors && props.errors[`lines.${key}.material_cost`]}
                                                                error={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].type ? true : false}
                                                                helperText={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].type ? liveErrors[ `worked_${key}` ].type : ""}
                                                                onChange={(e) => handleLiveChange(e, "worked", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {(type !== "view" && data.overview.approved) ? (
                                                                 <Select
                                                                 fullWidth
                                                                 name="who_id"
                                                                 value={item.who_id ? item.who_id : ""}
                                                                 error={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].who_id ? true : false}
                                                                 helperText={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].who_id ? liveErrors[ `worked_${key}` ].who_id : ""}
                                                                 onChange={(e) => handleLiveChange(e, "worked", key)}>
                                                                 {users && users.map((item, key) => {
                                                                     return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                 })}
                                                             </Select>
                                                            ) : (
                                                                <TextField
                                                                    value={item.who_name}
                                                                    type="text"
                                                                    name="who"
                                                                    fullWidth={true}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                type="date"
                                                                name="date"
                                                                value={item.date}
                                                                onChange={(e) => handleLiveChange(e, "worked", key)}
                                                                error={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].date ? true : false}
                                                                helperText={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].date ? liveErrors[ `worked_${key}` ].date : ""}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.what}
                                                                name="what"
                                                                type="text"
                                                                fullWidth={true}
                                                                onChange={(e) => handleLiveChange(e, "worked", key)}
                                                                error={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].what ? true : false}
                                                                helperText={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].what ? liveErrors[ `worked_${key}` ].what : ""}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.qty}
                                                                type="number"
                                                                name="qty"
                                                                fullWidth={true}
                                                                onChange={(e) => handleLiveChange(e, "worked", key)}
                                                                error={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].qty ? true : false}
                                                                helperText={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].qty ? liveErrors[ `worked_${key}` ].qty : ""}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.rate}
                                                                type="number"
                                                                name="rate"
                                                                fullWidth={true}
                                                                onChange={(e) => handleLiveChange(e, "worked", key)}
                                                                error={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].rate ? true : false}
                                                                helperText={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].rate ? liveErrors[ `worked_${key}` ].rate : ""}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.gross}
                                                                type="number"
                                                                name="gross"
                                                                fullWidth={true}
                                                                onChange={(e) => handleLiveChange(e, "worked", key)}
                                                                disabled={true}
                                                                error={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].rate ? true : false}
                                                                helperText={liveErrors && liveErrors[ `worked_${key}` ] && liveErrors[ `worked_${key}` ].rate ? liveErrors[ `worked_${key}` ].rate : ""}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {type !== "view" && <AppButton btnIcon="remove" style={{ background: 'red', color: 'white' }} onClick={() => handleRemoveLiveRecord("worked", key)} />}
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Labour Rate : <b>{totalLiveLabour()}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (step === 6) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Variations</h4>
                                {type !== "view" && <AppButton onClick={() => handleAddLiveRecord("variations")} text="add new" btnIcon="add" style={{ background: context.theme.sidebar.background, color: 'white', marginTop: 10 }} />}

                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                <TableCell align="left">Details</TableCell>
                                                <TableCell align="left">Net</TableCell>
                                                <TableCell align="left">VAT (%)</TableCell>
                                                <TableCell align="left">Gross</TableCell>
                                                <TableCell align="left">CC</TableCell>
                                                <TableCell align="left">PM</TableCell>
                                                <TableCell align="left">PC</TableCell>
                                                <TableCell align="left">Duration</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {live.variations.map((item, key) => {
                                                return (

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                value={item.type}
                                                                type="text"
                                                                name="type"
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].type ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].type ? variationErrors[ `variations_${key}` ].type : ""}
                                                                fullWidth={true}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.details}
                                                                type="text"
                                                                fullWidth={true}
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].details ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].details ? variationErrors[ `variations_${key}` ].details : ""}
                                                                name="details"
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={item.net}
                                                                fullWidth={true}
                                                                type="number"
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].net ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].net ? variationErrors[ `variations_${key}` ].net : ""}
                                                                name="net"
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                fullWidth
                                                                value={item.vat}
                                                                name="vat"
                                                                type="number"
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].vat ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].vat ? variationErrors[ `variations_${key}` ].vat : ""}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}>
                                                                {vat.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.name}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                value={totalVariationSingle(key)}
                                                                disabled={type === "view" || !data.overview.approved}
                                                                type="number"
                                                                fullWidth={true}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].gross ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].gross ? variationErrors[ `variations_${key}` ].gross : ""}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                name="charge_code"
                                                                value={item.charge_code}
                                                                fullWidth
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].charge_code ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].charge_code ? variationErrors[ `variations_${key}` ].charge_code : ""}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}>
                                                                {cc.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                name="payment_method"
                                                                value={item.payment_method}
                                                                fullWidth
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].payment_method ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].payment_method ? variationErrors[ `variations_${key}` ].payment_method  : ""}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}>
                                                                {pm.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Select
                                                                name="periodic_code"
                                                                value={item.periodic_code}
                                                                fullWidth
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].periodic_code ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].periodic_code ? variationErrors[ `variations_${key}` ].periodic_code : ""}
                                                                inputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}>
                                                                {pc.map((item, key) => {
                                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                step="1"
                                                                name="duration"
                                                                value={item.duration}
                                                                onChange={(e) => handleLiveChange(e, "variations", key)}
                                                                error={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].duration ? true : false}
                                                                helperText={variationErrors && variationErrors[ `variations_${key}` ] && variationErrors[ `variations_${key}` ].duration ? variationErrors[ `variations_${key}` ].duration : ""}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || !data.overview.approved || item.approved) ? true : false,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {type !== "view" && !item.approved && <AppButton btnIcon="remove" style={{ background: 'red', color: 'white' }} onClick={() => handleRemoveLiveRecord("variations", key)} />}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {type !== "view" && approver && (
                                                                variantsLoading.indexOf(item.id) == -1 ? (
                                                                    <Tooltip
                                                                        title={
                                                                            item.id == undefined 
                                                                                ? "Please save the variation first before approving"
                                                                                : item.approved 
                                                                                    ? "Unapprove variation"
                                                                                    : "Approve variation"
                                                                        }
                                                                    >
                                                                        <div>
                                                                            <Checkbox 
                                                                                defaultChecked={item.approved} 
                                                                                style={{ color: "green" }}
                                                                                onChange={
                                                                                    (e) => {handleVariantChange(item)}
                                                                                }
                                                                                disabled={variantsLoading.length > 0 || item.id == undefined}
                                                                            />
                                                                        </div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <CircularProgress sx={{ color: "green" }} size={20} />
                                                                )
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Variations : <b>{totalVariations()}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (authorisedExtendedForm && step === 7) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Lead Indicators</h4>
                                {type !== "view" && !data.overview.approved && <AppButton onClick={() => handleAddRecord("leads_indicator")} text="add new" btnIcon="add" style={{ background: context.theme.sidebar.background, color: 'white', marginTop: 10 }} />}

                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell align="center" colSpan={2}>
                                                    <b>Month</b>
                                                </TableCell>
                                                <TableCell align="center" colSpan={2}>
                                                    <b>Quarter</b>
                                                </TableCell>
                                                <TableCell align="center" colSpan={1}>
                                                </TableCell>
                                                <TableCell align="center" colSpan={2}>
                                                    <b>Project</b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Measure</TableCell>
                                                <TableCell align="left">Target</TableCell>
                                                <TableCell align="left">Actual</TableCell>
                                                <TableCell align="left">Target</TableCell>
                                                <TableCell align="left">Actual</TableCell>
                                                <TableCell align="left">Run Rate</TableCell>
                                                <TableCell align="left">Target</TableCell>
                                                <TableCell align="left">Actual</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data['leads_indicator'] && data['leads_indicator'].map((item, key) => {
                                                return (

                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.measure}
                                                                name="measure"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.monthTarget}
                                                                name="monthTarget"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.monthActual}
                                                                name="monthActual"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.quarterTarget}
                                                                name="quarterTarget"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.quarterActual}
                                                                name="quarterActual"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.runRate}
                                                                name="runRate"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.projectTarget}
                                                                name="projectTarget"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.projectActual}
                                                                name="projectActual"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "leads_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {type !== "view" && !data.overview.approved && <AppButton btnIcon="remove" style={{ background: 'red', color: 'white' }} onClick={() => handleRemoveRecord("leads_indicator", key)} />}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="page-section padding-lg">

                                <h4>Lag Indicators</h4>
                                {type !== "view" && !data.overview.approved && <AppButton onClick={() => handleAddRecord("lag_indicator")} text="add new" btnIcon="add" style={{ background: context.theme.sidebar.background, color: 'white', marginTop: 10 }} />}

                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell align="center" colSpan={2}>
                                                    <b>Month</b>
                                                </TableCell>
                                                <TableCell align="center" colSpan={2}>
                                                    <b>Quarter</b>
                                                </TableCell>
                                                <TableCell align="center" colSpan={1}>
                                                </TableCell>
                                                <TableCell align="center" colSpan={2}>
                                                    <b>Project</b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Measure</TableCell>
                                                <TableCell align="left">Target</TableCell>
                                                <TableCell align="left">Actual</TableCell>
                                                <TableCell align="left">Target</TableCell>
                                                <TableCell align="left">Actual</TableCell>
                                                <TableCell align="left">Run Rate</TableCell>
                                                <TableCell align="left">Target</TableCell>
                                                <TableCell align="left">Actual</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data['lag_indicator'] && data['lag_indicator'].map((item, key) => {
                                                return (

                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.measure}
                                                                name="measure"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.monthTarget}
                                                                name="monthTarget"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.monthActual}
                                                                name="monthActual"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.quarterTarget}
                                                                name="quarterTarget"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.quarterActual}
                                                                name="quarterActual"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.runRate}
                                                                name="runRate"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.projectTarget}
                                                                name="projectTarget"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                                value={item.projectActual}
                                                                name="projectActual"
                                                                fullWidth
                                                                onChange={(e) => handleChange(e, "lag_indicator", key)}
                                                                InputProps={{
                                                                    readOnly: (type === "view" || data.overview.approved) ? true : false,
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {type !== "view" && !data.overview.approved && <AppButton btnIcon="remove" style={{ background: 'red', color: 'white' }} onClick={() => handleRemoveRecord("lag_indicator", key)} />}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (step === 8) {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-1"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Menu step={step} setStep={setStep} isApproved={data.overview.approved} />
                        </div>
                        <div className="col-lg-11">
                            <div className="page-section padding-lg">
                                <h4>Sign</h4>
                                <TextField
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    name="name"
                                    value={data.sign.name}
                                    onChange={(e) => handleChange(e, "sign", false)}
                                    error={errors && errors.sign && errors.sign.name ? true : false}
                                    helperText={errors && errors.sign && errors.sign.name ? errors.sign.name : ""}
                                    placeholder="Name"
                                    fullWidth
                                    InputProps={{
                                        readOnly: (type === "view" || data.overview.approved) ? true : false,
                                    }}
                                />
                                <TextField
                                    type="date"
                                    name="date"
                                    value={data.sign.date}
                                    onChange={(e) => handleChange(e, "sign", false)}
                                    error={errors && errors.sign && errors.sign.date ? true : false}
                                    helperText={errors && errors.sign && errors.sign.date ? errors.sign.date : ""}
                                    InputProps={{
                                        readOnly: (type === "view" || data.overview.approved) ? true : false,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
