import React from "react";
import MUIDataTable from "mui-datatables";
export default function FilterTable({ columns, data, title, options }) {
  return (
    <MUIDataTable
      data={data}
      columns={columns}
      options={options}
      title={title}
    />
  );
}
