import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CanDelete } from "../../../services/Permissions";
import FilterTable from "../../common/FilterTable";
import AppButton from "../../common/AppButton";
import { CostingsApi } from "@unity/components";
import ApiLoader from "../../common/ApiLoader";
import Loading from "../../common/Loading";

export default function ReviewIndex({ context, create, read, edit, admin, index_params }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);

  const getJobs = async () => {
    const res = await CostingsApi.getReviewCostings(index_params);
    setData(res.data);
  };

  const handleApprove = async (id) => {
    setLoading({ status: true, data: 'Approving ABC, please wait...' });
    const res = await CostingsApi.updateApproved(id, {});

    if (res.errors) {
      setLoading({ status: true, data: 'Unable to approve ABC!' });
      setTimeout(() => {}, 3000);
    } else {
      setLoading({ status: false });
    }

    await getJobs()
  }

  const activeColumns = [
    { name: "ref", label: "Reference" },
    { name: "author_name", label: "Author" },
    { name: "name", label: "Name" },
    { name: "customer_name", label: "Customer Name" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Link
                to={{
                  pathname: `/abc/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              {admin && ((data[dataIndex].requested_approval && !data[dataIndex].approved) || data[dataIndex].on_hold) &&
                <Link
                  to={{
                    pathname: `/abc/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="check"
                    btnIcon="check"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 15
                    }}
                  />
                </Link>
              }

              {admin && data[dataIndex].checked && !data[dataIndex].approved &&
                <AppButton
                  text="approve"
                  btnIcon="check"
                  style={{
                    background: 'green',
                    color: "white",
                    marginLeft: 15
                  }}
                  onClick={() => handleApprove(data[dataIndex].id)}
                />
              }

            </div>
          );
        },
        filter: false,
      },
    },
  ];

  if (CanDelete("abc")) {
    var select = "multiple";
  } else {
    var select = "none";
  }

  const deletableOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {
        const res = await CostingsApi.deleteCostings(data[item.dataIndex].id);
      });
    },
  };
  
  
  useEffect(() => {
    getJobs();
  }, []);


  if (read && data) {
    return (
      <>
        <FilterTable columns={activeColumns} data={data} options={deletableOptions} />
        <ApiLoader status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <Loading />;
  }
}
