import React, { useContext } from "react";
import {
  IoIosCube,
  IoIosEye,
  IoIosPeople,
  IoIosToday,
  IoMdRibbon
} from "react-icons/io";
import { authorisedGroups } from "../../../constants";
import AuthContext from "../../../services/AuthContext";

export default function Menu({ step, setStep, isApproved }) {
  const context = useContext(AuthContext);
  const authorisedExtendedForm = authorisedGroups[context.auth.group_uuid];

  return (
    <div className="container-fluid">
      <div className="row justify-content-lg-center stepper menu">
        <div
          style={{
            background: context.theme.sidebar.background,
            color: context.theme.sidebar.font,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          className={step === 1 ? "step-active-abc col-lg-12" : "col-lg-12"}
          onClick={() => setStep(1)}
        >
          <IoIosEye size={25} />
          <p>Overview</p>
        </div>
        <div
          className={step === 2 ? "step-active-abc col-lg-12" : "col-lg-12"}
          onClick={() => setStep(2)}
          style={{
            background: context.theme.sidebar.background,
            color: context.theme.sidebar.font,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <IoIosCube size={25} />
          <p>Revenue</p>
        </div>
        <div
          className={step === 3 ? "step-active-abc col-lg-12" : "col-lg-12"}
          onClick={() => setStep(3)}
          style={{
            background: context.theme.sidebar.background,
            color: context.theme.sidebar.font,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <IoMdRibbon size={25} />
          <p>Finance Approval</p>
        </div>
        <div
          className={step === 4 ? "step-active-abc col-lg-12" : "col-lg-12"}
          onClick={() => setStep(4)}
          style={{
            background: context.theme.sidebar.background,
            color: context.theme.sidebar.font,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <IoIosPeople size={25} />
          <p>Costs</p>
        </div>
        {isApproved &&
          <>
            <div
              className={step === 5 ? "step-active-abc col-lg-12" : "col-lg-12"}
              onClick={() => setStep(5)}
              style={{
                background: context.theme.sidebar.background,
                color: context.theme.sidebar.font,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <IoIosPeople size={25} />
              <p>Live Labour</p>
            </div>
            <div
              className={step === 6 ? "step-active-abc col-lg-12" : "col-lg-12"}
              onClick={() => setStep(6)}
              style={{
                background: context.theme.sidebar.background,
                color: context.theme.sidebar.font,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <IoIosToday size={25} />
              <p>Variations</p>
            </div>
          </>
        }
        {authorisedExtendedForm &&
          <div
            className={step === 7 ? "step-active-abc col-lg-12" : "col-lg-12"}
            onClick={() => setStep(7)}
            style={{
              background: context.theme.sidebar.background,
              color: context.theme.sidebar.font,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <IoIosToday size={25} />
            <p>Lead / Lag</p>
          </div>
        }
        <div
          className={step === 8 ? "step-active-abc col-lg-12" : "col-lg-12"}
          onClick={() => setStep(8)}
          style={{
            background: context.theme.sidebar.background,
            color: context.theme.sidebar.font,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <IoIosToday size={25} />
          <p>Sign</p>
        </div>
      </div>
    </div>
  );
}
