import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin } from "../../services/Permissions";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import ModuleTabs from "../../components/common/Tabs";
import ActiveIndex from "../../components/modules/abc/ActiveIndex";
import CompleteIndex from "../../components/modules/abc/CompleteIndex";
import DraftIndex from "../../components/modules/abc/DraftIndex";
import RestoreIndex from "../../components/modules/abc/RestoreIndex";
import ReviewIndex from "../../components/modules/abc/ReviewIndex";
import JobCostingSettings from "../../components/modules/abc/JobCostingSettings";

export default function JobCosting({ context, route }) {
  const [value, setValue] = useState(0);

  const create = CanCreate("abc");
  const read = CanRead("abc");
  const edit = CanUpdate("abc");
  const admin = CanModuleAdmin("abc");
  const index_params = { read_all: false };

  if (CanReadAll("abc")) {
    index_params.read_all = true;
  }

  const tabs = [
    { label: "active", icon: "build" },
    { label: "draft", icon: "history" },
    { label: "completed", icon: "check" },
  ];

  if (admin) {
    tabs.push(
        { label: "under review", icon: "rate_review" },
        { label: "trash", icon: "delete" },
        { label: "settings", icon: "settings" });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  
  useEffect(() => {}, []);



  return (
    <>
      {create ? (
        <AppHeaderComponent
          addNew={() => <Link to={"/abc/create"}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }
              }
            />
          </Link>}
          context={context}
          theme={context.theme}
          name={"abc"}
        />
      ) : (
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name={"abc"}
        />
      )}
      
      <ModuleTabs tabs={tabs} value={value} setValue={setValue} handleChange={handleChange} />
      <div style={{ paddingTop: 180 }}>
        {value === 0 && 
          <ActiveIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            index_params={index_params}
          />}
        {value === 1 && 
          <DraftIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            index_params={index_params}
          />}
        {value === 2 && 
          <CompleteIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            index_params={index_params}
          />}
        {value === 3 && 
          <ReviewIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            index_params={index_params}
          />}
        {value === 4 && 
          <RestoreIndex
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            index_params={index_params}
          />}
        {value === 5 &&
          <JobCostingSettings
            context={context}
          />}
      </div>
    </>
  );
}
