import React, { useEffect, useState } from "react";
import JobCostingForm from "../../components/modules/abc/JobCostingForm";
import Loading from "../../components/common/Loading";
import { CostingsApi, CodesApi, PersonnelApi, AppHeaderComponent, BusinessApi } from "@unity/components";
import ApiLoader from "../../components/common/ApiLoader";
import { useHistory } from "react-router";
import { authorisedGroups } from "../../constants";
import { CircularProgress, Typography } from "@mui/material";
import { ContactSupportOutlined } from "@material-ui/icons";

export default function JobCostingEdit({ route, context }) {

  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [live, setLive] = useState({ worked: [], variations: [] });
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState(false);
  const [liveErrors, setLiveErrors] = useState(false);
  const [variationErrors, setVariationErrors] = useState(false);
  const [clients, setClients] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  const [productCategories, setProductCategories] = useState(false)
  const [users, setUsers] = useState(false);
  const [creditCheck, setCreditCheck] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [dataToBeSaved, setDataToBeSaved] = useState([]);
  const [variationDataToBeSaved, setVariationDataToBeSaved] = useState([])

  const authorisedExtendedForm = authorisedGroups[context.auth.group_uuid];
  const totals = [
      { category: "revenue", value: null, warning: false },
      { category: "labour", value: null, warning: false },
      { category: "materials", value: null, warning: false },
      { category: "worked", value: null, warning: false },
      { category: "variations", value: null, warning: false },
      { category: "estimated_total", value: null, warning: false },
      { category: "actual_total", value: null, warning: false }
  ];

  let history = useHistory();

  const getData = async () => {
    setLoading({status: true, data: "Loading Data..."});
    const res = await CostingsApi.getCostingsSingle(route.match.params.id);
    setData(res.data);
    await getAllClients(res.data.overview.customer_name);
    if (res.data.overview.associated_bu) {
      const res2 = await CodesApi.subOptionsIndexForOption(res.data.overview.associated_bu);
      setProductCategories(res2.data);
    }
    if (res.data.finance.checked) {
      setCheckBox(true);
    }

    const newData = { ...creditCheck };
    newData["customer_id"] = res.data.overview.customer_id;
    setCreditCheck(newData);
    setLoading({status: false, data: false});
  }

  const getLive = async () => {
    const res = await CostingsApi.getLiveLines(route.match.params.id);
    setLive(res.data);
  }

  const getAllClients = async (search = "") => {
    try {
      const rules = [{field: "is_client", operator: "=", value: true}];
      if(search !== "") {
        search = search.replace("🌍 ", "");
        search = search.replace("🔒 ", "");
        rules.push({field: "customer_name", operator: "LIKE", value: `%${search}%`})
      }

      const res = await BusinessApi.businessIndexFiltered({
        rules: rules,
        page: 1
      });
      if(!res.ok) throw "Call Failed";

      setClients(res.data.data);
    } catch (error) {
      alert(`Failed to Pull Clients ${error}`);
    }
  }

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  }

  const getCategories = async (id) => {
    const res = await CodesApi.subOptionsIndexForOption(id);
    setProductCategories(res.data);
  }

  const getUserData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setUsers(res.data);
  }

  const handleSave = async () => {
    setLoading({ status: true, data: 'Saving, please wait...' });
    const res = await CostingsApi.updateCostings(route.match.params.id, data);
    if (res.errors) {
      setErrors(res.errors);
      setLoading({ status: true, data: 'Validation Errors, Please check all fields' });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      await CostingsApi.saveTotals({ costing_id: parseInt(route.match.params.id), totals: totals});
      await getData();
      setLoading({ status: true, data: 'Saving Success, please wait...' });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  const handleSaveLiveLabour = async () => {

    let failedCallArr = dataToBeSaved
    let failedArr = []
    let failedVarArr = []
    const obj = new Object 
    const variationObj = new Object 
    
    Promise.all(dataToBeSaved.map( async index => {

      let res;
      setLoading({ status: true, data: 'Saving, please wait...' });

      if( live.worked[index] && live.worked[index].id ){

        res = await CostingsApi.liveLabourUpdate(live.worked[index].id, { "labour": { ...live.worked[index], "costing_id": parseInt(live.costing_id) } });

      }else{
        res = await CostingsApi.liveLabourStore( {"labour" : {...live.worked[index] , "costing_id" : parseInt(live.costing_id) } });
      }

      return { "index" : index , ...res};
    }    )

    ).then( (values) => {
      values.forEach( i => {
        if(i && i.errors){
          obj[`worked_${i.index}`] = i.errors
          failedArr.push( i.index )
        } 
      } 
      )
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
      setDataToBeSaved(failedArr)
      setLiveErrors( obj )
    }
    ).then( 
      console.log("Complete")
    )

//----------------------------------------------------
    Promise.all(variationDataToBeSaved.map( async varIndex => {

      let res;
      setLoading({ status: true, data: 'Saving, please wait...' });

      if( live.variations[varIndex].id ){
        res = await CostingsApi.variationUpdate(live.variations[varIndex].id, { "variation": { ...live.variations[varIndex], "costing_id": parseInt(live.costing_id) } });

      }else{
        res = await CostingsApi.variationStore( {"variation" : {...live.variations[varIndex] , "costing_id" : parseInt(live.costing_id) } });
      }

      return { "index" : varIndex , ...res};
    }    )

    ).then( (values) => {
      values.forEach( i => {
        if(i && i.errors){
          variationObj[`variations_${i.index}`] = i.errors
          failedVarArr.push( i.index )
        } 
      } 
      )
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
      setVariationDataToBeSaved(failedVarArr)
      setVariationErrors( variationObj )
    }
    ).then( 
      console.log("Complete")
    )
  }

  const handleChange = (e, root, key) => {
    if (key === false) {
      const newData = { ...data };
      if (e.target.name === "requested_by_id") {
        const userObj = users.find(t => t.id === e.target.value);
        newData[root]["requested_by_name"] = userObj.name;
      }
      newData[root][e.target.name] = e.target.value;
      setData(newData);
    } else {
      const newData = { ...data };
      newData[root][key][e.target.name] = e.target.value;
      if (root === "labour" && newData[root][key]["qty"] && newData[root][key]["rate"]) {
        const st = newData[root][key]["qty"] * newData[root][key]["rate"];
        newData[root][key]["cost"] = parseFloat(st.toFixed(2));
      }
      setData(newData);
    }
  }

  const handleLiveChange = (e, root, key) => {
      const newData = { ...live };
      const singleDataArray = dataToBeSaved;
      const varDataArray = variationDataToBeSaved

      if(step === 5){
        if(!singleDataArray.includes( key )){
          singleDataArray.push( key );
        }
        
        setDataToBeSaved( singleDataArray.sort() )
      }

      if(step === 6){
        if(!varDataArray.includes( key )){
          varDataArray.push( key );
        }

      setVariationDataToBeSaved( varDataArray.sort() )
      }

      let newValue = e.target.value;
      if(e.target.name === "duration") {
        newValue = parseInt(newValue);
      } else if(e.target.name === "net") {
        newValue = parseFloat(newValue);
      }
      newData[root][key][e.target.name] = newValue;
      if (root === "worked") {
        if (e.target.name === "who_id") {
          const userObj = users.find(t => t.id === e.target.value);
          newData[root][key]["who_name"] = userObj.name;
        }
        if (newData[root][key]["qty"] && newData[root][key]["rate"]) {
            const st = newData[root][key]["qty"] * newData[root][key]["rate"];
            newData[root][key]["gross"] = parseFloat(st.toFixed(2));
        }
      } else {
        if (newData["variations"][key]["net"] && newData["variations"][key]["vat"]) {
          const tt = parseFloat(newData["variations"][key]["net"]) + (newData["variations"][key]["net"] * (newData["variations"][key]["vat"] / 100));
          newData["variations"][key]["gross"] = parseFloat(tt.toFixed(2));
        }
      }

      setLive(newData);
  }

  const handleAddRecord = (root) => {
    const newData = {
      ...data
    };
    newData[root].push({});
    setData(newData);
  }

  const handleAddLiveRecord = (root) => {
    const newData = { ...live };
    newData[root].push({});
    setLive(newData);
  }

  const handleRemoveRecord = async (root, key) => {
    const newData = { ...data };
    if (newData[root][key].id) {
      await CostingsApi.deleteLine(newData[root][key].id);
      newData[root].splice(key, 1);
    } else {
      newData[root].splice(key, 1);
    }
    setData(newData);
    await CostingsApi.saveTotals({ costing_id: parseInt(route.match.params.id), totals: totals});
  }

  const handleRemoveLiveRecord = async (root, key) => {
    const tempArr = dataToBeSaved;
    const tempVarArr = variationDataToBeSaved;
    const newData = { ...live };

    if (newData[root][key].id) {
      if (root === "worked") {
        await CostingsApi.deleteLiveLabour(newData[root][key].id);
      } else {
        await CostingsApi.deleteVariation(newData[root][key].id);
      }
      newData[root].splice(key, 1);
    } else {
      newData[root].splice(key, 1);
    }
    setLive(newData);
    await CostingsApi.saveTotals({ costing_id: parseInt(route.match.params.id), totals: totals});

    if (root === "worked") {
    const i = tempArr.indexOf( key )
    delete tempArr[i]

    let newArr = tempArr.map( (index) => {
      
      if( index && index > i){
        return index - 1
      }
      return index
    } 
    
    )
    setDataToBeSaved( newArr.sort() )
  } else {
    const j = tempVarArr.indexOf( key )
    delete tempVarArr[j]

    let newVarArr = tempVarArr.map( (index) => {
      
      if( index && index > j){
        return index - 1
      }
      return index
    } 
    
    )
    setVariationDataToBeSaved( newVarArr.sort() )
  }

  }

  const handleCheckbox = () => {
    const newData = { ...data };
    if (checkBox) {
      newData["finance"]["set_checked"] = false;
      setCheckBox(false);
    } else {
      newData["finance"]["set_checked"] = true;
      setCheckBox(true);
    }
  }

  const handleCheckChange = (e) => {
    const newData = { ...creditCheck };
    newData[e.target.name] = e.target.value;
    setCreditCheck(newData);
  }

  // Page total for variations section
  const totalVariations = () => {
    var i = 0;
    live["variations"].map((item, key) => {
      var qty = parseFloat(item.duration ? item.duration : 0);
      var net = parseFloat(item.net ? item.net : 0);
      var vat = parseFloat(item.vat ? item.vat / 100 : 0);
      var t = (qty * net) + ((qty * net) * vat);
      i += t;
    });
    return i.toFixed(2);
  }

  // Page total for labour section
  const totalLabour = () => {
    var i = 0;
    data["labour"].map((item, key) => {
      var t = parseFloat(item.rate ? item.rate : 0) * parseFloat(item.qty ? item.qty : 1);
      i += t;
    })
    return i.toFixed(2);
  }

  // Page total for live labour section
  const totalLiveLabour = () => {
    var i = 0;
    live["worked"].map((item, key) => {
      var t = parseFloat(item.rate ? item.rate : 0) * parseFloat(item.qty ? item.qty : 1);
      i += t;
    });
    return i.toFixed(2);
  }

  // Page total for revenue section
  const totalRevenue = () => {
    var i = 0;
    data["revenue"].map((item, key) => {
      var qty = parseFloat(item.duration ? item.duration : 0);
      var net = parseFloat(item.net ? item.net : 0);
      var vat = parseFloat(item.vat ? item.vat / 100 : 0);
      var t = (qty * net) + ((qty * net) * vat);
      i += t;
    });
    return i.toFixed(2);
  }

  // Page total for materials section
  const totalMaterial = () => {
    var i = 0;
    data["materials"].map((item, key) => {
      var qty = parseFloat(item.quantity ? item.quantity : 0);
      var net = parseFloat(item.net_cost ? item.net_cost : 0);
      var vat = parseFloat(item.vat_cost ? item.vat_cost / 100 : 0);
      var dur = parseFloat( item.duration !== undefined ? item.duration : 1 );
      var t = ((qty * net) + ((qty * net) * vat)) * dur;
      i += t;
    });
    return i.toFixed(2);
  }

  // Row gross figure
  const totalVariationSingle = (key) => {
    var item = live["variations"][key];
    var net = parseFloat(item.net ? item.net : 0);
    var vat = parseFloat(item.vat ? item.vat / 100 : 0);
    var t = net + (net * vat);
    return t.toFixed(2);
  }

  const handleBusinessChange = async (id) => {
    const newData = { ...data };
    newData["overview"]["associated_bu"] = id;
    setData(newData);

    await getCategories(id);
  }

  const handleClientChange = async (client) => {
    const newData = { ...data };
    newData["overview"]["customer_id"] = client._id;
    newData["overview"]["customer_name"] = client.customer_name;
    setData(newData);

    const newData2 = { ...creditCheck };
    newData2["customer_id"] = client.id;
    setCreditCheck(newData2);
  }

  const handleCompleteState = async (id, state) => {
    console.log("STATE: ",state);
    setLoading({ status: true, data: 'Updating ABC completed status , please wait...' });
    const res = await CostingsApi.updateCompleted(id, { completed: state });
    
    if (res.errors) {
      setLoading({ status: true, data: 'Unable to update completed status on ABC!' });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  const handleVariationApproval = async (id) => {
    const res = await CostingsApi.approveVariation(id, {});
    
    if (res.errors) alert("Failed to Update Approval Status on Variantion. Please refresh and try again");
  }


  useEffect(() => {
    getData();
    getLive();
    // getAllClients();
    getBusinesses();
    getUserData();
  }, [])

  if(loading?.state) return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1vh"
    }}>
      <CircularProgress/>
      <Typography>Loading Form Contents...</Typography>
    </div>
  )

  console.log("checking the overview", data.overview);


  if (data && users) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="abc"
          subpage="edit"
          subpage2={data.overview.name}
          saveBtn={!data.overview.approved ? handleSave : handleSaveLiveLabour}
        />
        <div style={{ paddingTop: 120 }}>
          <JobCostingForm
            setLoading={setLoading}
            data={data} setData={setData}
            live={live}
            totals={totals}
            step={step} setStep={setStep}
            creditCheck={creditCheck} setCreditCheck={setCreditCheck}
            checkBox={checkBox} context={context}
            handleAddRecord={handleAddRecord}
            handleAddLiveRecord={handleAddLiveRecord}
            handleRemoveRecord={handleRemoveRecord}
            handleRemoveLiveRecord={handleRemoveLiveRecord}
            handleChange={handleChange}
            handleLiveChange={handleLiveChange}
            totalVariationSingle={totalVariationSingle}
            totalVariations={totalVariations}
            totalLabour={totalLabour}
            totalLiveLabour={totalLiveLabour}
            totalRevenue={totalRevenue}
            totalMaterial={totalMaterial}
            type="edit"
            errors={errors}
            liveErrors={liveErrors}
            clients={clients}
            businesses={businesses}
            productCategories={productCategories}
            handleClientChange={handleClientChange}
            handleBusinessChange={handleBusinessChange}
            handleCheckbox={handleCheckbox}
            handleCheckChange={handleCheckChange}
            users={users}
            authorisedExtendedForm={authorisedExtendedForm}
            handleCompleteState={handleCompleteState}
            handleVariationApproval={handleVariationApproval}
            getAllClients={getAllClients}
            loading={loading}
            variationErrors={variationErrors}
          />
        </div>
        <ApiLoader
          status={loading.status}
          data={loading.data}
        />
      </>
    )
  } else {
    return <Loading />
  }

}
