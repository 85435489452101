const authorisedGroups = {
    "06c1239b-64e8-4d7a-a59a-9b2843f05ed3": true,// Unity AI Software
    "7b7ca9f8-190b-43d5-a5f8-dd3082510b2d": true,// Unity AI Software STAGING!
    "644620e8-aa0f-4543-b967-7cee5243e358": true,// Unity Shared Services - Radical Labs
    "718dc09d-aa74-4483-8046-21f8c723988e": true,// Unity Shared Services - GTM Corporate
    "65f179bb-c9fd-441c-bb64-fe48d0afbd68": true,// Unity Shared Services - GTM Direct
    "8e6a2ac5-9057-4adb-9d05-f48584a08c8f": true,// Unity Shared Services - GTM Indirect
    "eec63992-5384-45f0-bd9e-09df6f9e8013": true,// Unity Shared Services - GTM Online
    "9cf99975-4e5d-46dc-b61b-80ace48d283d": true,// Unity Shared Services - Finance
    "c9406285-a6d7-49d2-a6ea-c77d8a6c8b6d": true,// Unity Shared Services - Fleet
    "dcd859c7-9ef5-4a9f-9b37-62467e3ef838": true,// Unity Shared Services - Sales
    "5af2445d-aa5c-4e9c-bfdb-121d983fda49": true,// Unity Shared Services - People
}

// These are the pre-set constant alias integers for the code types
const codeAlias = {
    "ChargeCodes": 1,
    "PaymentMethods": 2,
    "PeriodicCodes": 3,
    "VatRates": 6
}

export {
    authorisedGroups,
    codeAlias
}