import React, { useContext, useEffect, useState } from "react";
import Widget from "../../components/modules/dashboard/Widget";
import WeeklyLabour from "../../components/modules/reports/WeeklyLabour";
import Profitability from "../../components/modules/reports/Profitability";
import { CanModuleAdmin } from "../../services/Permissions";


export default function Dashboard({ context, route }) {
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState({ from: null, to: null });

  const current = new Date();
  const lastWeek = new Date(current.getTime() - 604800000);

  const admin = CanModuleAdmin("dashboard");

  const setDates = () => {
    const from = `${lastWeek.getFullYear()}-${fixDate(lastWeek.getMonth()+1)}-${fixDate(lastWeek.getDate())}`;
    const to = `${current.getFullYear()}-${fixDate(current.getMonth()+1)}-${fixDate(current.getDate())}`;
    const newFilter = { ...filter };
    newFilter["from"] = from;
    newFilter["to"] = to;
    setFilter(newFilter);
  }

  const fixDate = (num) => {
      if (num < 10) {
          return `0${num}`
      } else {
          return `${num}`
      }
  }

  const handleChange = (e) => {
    const newFilter = { ...filter };
    newFilter[e.target.name] = e.target.value;
    setFilter(newFilter);
  }

  useEffect(() => {
    setDates();
  }, []);

  if (admin) {
    return (
      <>
        {(value === 0) && (
        <div className="container-fluid">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0 -5px",
              marginBottom: 15,
            }}
          >
            <div style={{ flex: 1, margin: "0 5px" }}>
              <Widget
                tag={1}
                setValue={setValue}
                button={true}
                count={null}
                title="Weekly Labour Report"
                bg="red"
              />
            </div>
            <div style={{ flex: 1, margin: "0 5px" }}>
              <Widget
                tag={2}
                setValue={setValue}
                button={true}
                count={null}
                title="Profitability Report"
                bg="green"
              />
            </div>
          </div>
        </div>
        )}

        {(value === 1) && (
          <WeeklyLabour
            setValue={setValue}
            handleChange={handleChange}
            filter={filter}
            bg={context.theme.sidebar.background}
            admin={admin} />
        )}

        {(value === 2) && (
          <Profitability
            setValue={setValue}
            handleChange={handleChange}
            filter={filter}
            bg={context.theme.sidebar.background}
            admin={admin} />
        )}
      </>
    );
  } else {
    return <p>You do not have permission to view this page!</p>;
  }
}
