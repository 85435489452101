import React, { useEffect, useState } from "react";
import JobCostingForm from "../../components/modules/abc/JobCostingForm";
import Loading from "../../components/common/Loading";
import { CostingsApi, ClientsApi, CodesApi, PersonnelApi, BusinessApi, AppHeaderComponent } from "@unity/components";
import ApiLoader from "../../components/common/ApiLoader";
import { useHistory } from "react-router-dom";
import { authorisedGroups } from "../../constants";

export default function JobCostingCreate({ route, context }) {

  const temp_data = {
    overview: {
      requested_by_id: context.auth.agent_id
    },
    revenue: [],
    finance: {},
    labour: [],
    materials: [],
    "leads_indicator": [],
    "lag_indicator": [],
    sign: {}
  }

  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(temp_data);
  const [live, setLive] = useState({ worked: [], variations: [] });
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState(false);
  const [clients, setClients] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  const [productCategories, setProductCategories] = useState(false);
  const [users, setUsers] = useState(false);
  const [creditCheck, setCreditCheck] = useState(false);

  const authorisedExtendedForm = authorisedGroups[context.auth.group_uuid];
  const totals = [
    { category: "revenue", value: null, warning: false },
    { category: "labour", value: null, warning: false },
    { category: "materials", value: null, warning: false },
    { category: "worked", value: null, warning: false },
    { category: "variations", value: null, warning: false },
    { category: "estimated_total", value: null, warning: false },
    { category: "actual_total", value: null, warning: false }
];
  
  let history = useHistory();

  const handleSave = async () => {
    setLoading({ status: true, data: 'Saving, please wait...' });
    const res = await CostingsApi.saveCostings(data);

    if (res.errors) {
      setErrors(res.errors);
      setLoading({ status: true, data: 'Validation Errors, Please check all fields' });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: 'Saving Success, please wait...' });
      setTimeout(() => {
        history.push('/abc/index');
      }, 3000);
    }
  }

  const getAllClients = async (search = "") => {
    try {
      const rules = [{field: "is_client", operator: "=", value: true}];
      if(search !== "") rules.push({field: "customer_name", operator: "LIKE", value: `%${search}%`});

      const res = await BusinessApi.businessIndexFiltered({
        rules: rules,
        page: 1
      });
      if(!res.ok) throw "Call Failed";

      setClients(res.data.data);
    } catch (error) {
      alert(`Failed to Pull Clients - ${error}`);
    }
  }

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  }

  const getCategories = async (id) => {
    const res = await CodesApi.subOptionsIndexForOption(id);
    setProductCategories(res.data);
  }

  const getUserData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setUsers(res.data);
  }

  const handleChange = (e, root, key) => {
    if (key === false) {
      const newData = { ...data };
      if (e.target.name === "requested_by_id") {
        const userObj = users.find(t => t.id === e.target.value);
        newData[root]["requested_by_name"] = userObj.name;
      }
      newData[root][e.target.name] = e.target.value;
      setData(newData);
    } else {
      const newData = { ...data };
      newData[root][key][e.target.name] = e.target.value;
      if (root === "labour" && newData[root][key]["qty"] && newData[root][key]["rate"]) {
        const st = newData[root][key]["qty"] * newData[root][key]["rate"];
        newData[root][key]["cost"] = st.toFixed(2);
      }
      setData(newData);
    }
  }

  const handleAddRecord = (root) => {
    const newData = { ...data };
    newData[root].push({});
    setData(newData);
  }

  const handleRemoveRecord = (root, key) => {
    const newData = { ...data };
    newData[root].splice(key, 1);
    setData(newData);
  }

  const totalLabour = () => {
    var i = 0;
    data["labour"].map((item, key) => {
      var t = parseFloat(item.rate ? item.rate : 0) * parseFloat(item.qty ? item.qty : 1);
      i += t;
    });
    return i.toFixed(2);
  }

  const totalRevenue = () => {
    var i = 0;
    data["revenue"].map((item, key) => {
      var net = parseFloat(item.net ? item.net : 0);
      var vat = parseFloat(item.vat ? item.vat : 0);
      var t = net + (vat / 100 * net);
      i += t;
    });
    return i.toFixed(2);
  }

  const totalMaterial = () => {
    var i = 0;
    data["materials"].map((item, key) => {
      var qty = parseFloat(item.quantity ? item.quantity : 0);
      var net = parseFloat(item.net_cost ? item.net_cost : 0);
      var vat = parseFloat(item.vat_cost ? item.vat_cost / 100 : 0);
      var dur = parseFloat( item.duration !== undefined ? item.duration : 1 );
      var t = ((qty * net) + ((qty * net) * vat)) * dur;
      i += t;
    });
    return i.toFixed(2);
  }

  const handleBusinessChange = async (id) => {
    const newData = { ...data };
    newData["overview"]["associated_bu"] = id;
    setData(newData);
    await getCategories(id);
  }

  const handleClientChange = async (client) => {
    const newData = { ...data };
    newData["overview"]["customer_id"] = client._id;
    newData["overview"]["customer_name"] = client.customer_name;
    setData(newData);
  }

  const handleCompleteState = async (id, state) => {}


  useEffect(() => {
    getAllClients();
    getBusinesses();
    getUserData();
  }, [])


  if (data && users) {
    return (
      <>
        <AppHeaderComponent context={context} theme={context.theme} name="abc" subpage="create" saveBtn={handleSave} />
        <div style={{ paddingTop: 140 }}>
          <JobCostingForm
            data={data} setData={setData}
            live={live}
            totals={totals}
            step={step} setStep={setStep}
            creditCheck={creditCheck} setCreditCheck={setCreditCheck}
            context={context}
            handleAddRecord={handleAddRecord}
            handleRemoveRecord={handleRemoveRecord}
            handleChange={handleChange}
            totalLabour={totalLabour}
            type="create"
            errors={errors}
            totalMaterial={totalMaterial}
            totalRevenue={totalRevenue}
            clients={clients}
            businesses={businesses}
            productCategories={productCategories}
            handleClientChange={handleClientChange}
            handleBusinessChange={handleBusinessChange}
            users={users}
            authorisedExtendedForm={authorisedExtendedForm}
            handleCompleteState={handleCompleteState}
            getAllClients={getAllClients}
          />
        </div>
        <ApiLoader status={loading.status} data={loading.data} />
      </>
    )
  } else {
    return <Loading />
  }
}
