import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CanDelete } from "../../../services/Permissions";
import FilterTable from "../../common/FilterTable";
import AppButton from "../../common/AppButton";
import { CostingsApi } from "@unity/components";
import ApiLoader from "../../common/ApiLoader";
import Loading from "../../common/Loading";

export default function DraftIndex({ context, create, read, edit, admin, index_params }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);

  const getJobs = async () => {
    const res = await CostingsApi.getDraftCostings(index_params);
    setData(res.data);
  };

  const handleApproveRequest = async (id, cancel) => {
    let message_a = 'Checking ABC for approval request, please wait...';
    let message_b = 'Unable to send ABC for approval!';

    if (cancel) {
        message_a = 'Cancelling approval request for ABC, please wait...';
        message_b = 'Unable to cancel approval request for ABC!';
    }

    setLoading({ status: true, data: message_a });
    const res = await CostingsApi.requestApproval(id, {});

    if (res.errors) {
      setLoading({ status: true, data: message_b });
      setTimeout(() => {}, 3000);
    } else {
      setLoading({ status: false });
    }

    await getJobs()
  }

  const activeColumns = [
    { name: "ref", label: "Reference" },
    { name: "author_name", label: "Author" },
    { name: "name", label: "Name" },
    { name: "customer_name", label: "Customer Name" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Link
                to={{
                  pathname: `/abc/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              {edit && !data[dataIndex].requested_approval &&
                <Link
                  to={{
                    pathname: `/abc/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 15
                    }}
                  />
                </Link>
              }

              {edit && data[dataIndex].signed && !data[dataIndex].approved ? (
                data[dataIndex].requested_approval ? (
                  <AppButton
                    text="cancel approval request"
                    btnIcon="timer"
                    style={{
                      background: 'orange',
                      color: "white",
                      marginLeft: 15
                    }}
                    onClick={() => handleApproveRequest(data[dataIndex].id, true)}
                  />
                ) : (
                  <AppButton
                  text="request approval"
                  btnIcon="notifications"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 15

                  }}
                  disabled={data[dataIndex].requested_approval}
                  onClick={() => handleApproveRequest(data[dataIndex].id, false)}
                />
                )
              ) : (
                <>
                </>
              )}

            </div>
          );
        },
        filter: false,
      },
    },
  ];

  if (CanDelete("abc")) {
    var select = "multiple";
  } else {
    var select = "none";
  }

  const deletableOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {
        const res = await CostingsApi.deleteCostings(data[item.dataIndex].id);
      });
    },
  };
  
  
  useEffect(() => {
    getJobs();
  }, []);


  if (read && data) {
    return (
      <>
        <FilterTable columns={activeColumns} data={data} options={deletableOptions} />
        <ApiLoader status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <Loading />;
  }
}
